const url = 'https://api.rifahit.com';
const url_storage = 'https://api.rifahit.com/storage/';

const api = {

    login: async (email, password) => {
        const response = await fetch(`${url}/login/parceiro`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password,
                empresa: process.env.REACT_APP_EMPRESA
            })
        });
        return response.json();
    },

    cliente: {

        exists: async (celular) => {
            const response = await fetch(`${url}/clientes/exists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa: process.env.REACT_APP_EMPRESA,
                    celular
                })
            });
            return response.json();
        
        },

        pagamento: {

            create: async (rifa, quantidade, nome, email, celular, cpf, token) => {
                const response = await fetch(`${url}/pagamentos/createbyparceiro`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({
                        rifa: rifa,
                        quantidade: quantidade,
                        nome: nome,
                        email: email,
                        celular: celular,
                        cpf: cpf,
                        empresa: process.env.REACT_APP_EMPRESA
                    })
                });
                return response.json();
            },

            check: async (id) => {
                const response = await fetch(`${url}/pagamentos/check`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id
                    })
                });
                return response.json();
            }

        }

    },

    empresa: {

        get: async (empresa) => {
            const response = await fetch(`${url}/empresas/getcliente`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa: empresa
                })
            });
            return response.json();
        }

    }

}

export { url, api, url_storage };