import {Link} from 'react-router-dom';

import './footer.css';

function Footer() {
    return(
        <footer>
            <div class='links'>
                <Link to='/termos-e-politica'>Termos de Uso | Política de Privacidade</Link>
            </div>
            <div class='made mt-2'>
                Copyright © {new Date().getFullYear()} RifaHit. Todos os direitos reservados.
            </div>
        </footer>
    )
}

export default Footer