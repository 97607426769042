import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// pages
import Login from './pages/Login.js';
import Home from './pages/Home.js';

// components
import Footer from './components/footer/Footer.js';
import Menu from "./components/menu/Menu.js";

// context
import { AuthProvider, isAuthenticaded} from './contexts/AuthContext.js';
import { EmpresaProvider } from './contexts/EmpresaContext.js';

const PrivateRoute = (props) => {
  return isAuthenticaded() ? <Route {...props}/> : <Navigate to='/'/>
}

function App() {

  return (
    <BrowserRouter>
      <AuthProvider>
        <EmpresaProvider>
          <Menu/>
          <Routes>
            <Route path='/' element={isAuthenticaded() ? <Home/> : <Login/> }></Route>
            <Route path='*' element={<Navigate to='/'/>}></Route>
          </Routes>
          <Footer/>
        </EmpresaProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;