// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [usuarioLogado, setUsuarioLogado] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        // let usuario = localStorage.getItem('usuario');
        // if (usuario) {
        //     setUsuarioLogado(JSON.parse(usuario));
        // }
        let token = localStorage.getItem('token');
        if (token) {
            setToken(token);
        }
    }, []);

    const login = (token) => {
        // localStorage.setItem('usuario', JSON.stringify(usuario));
        // setUsuarioLogado(usuario);
        localStorage.setItem('token', token);
        setToken(token);
    };

    const logout = () => {
        // localStorage.removeItem('usuario');
        // setUsuarioLogado(null);
        localStorage.removeItem('token');
        setToken(null);
    };

    const isUsuarioLogado = () => {
        // return usuarioLogado !== null;
        return token !== null;
    };

    const authContextValue = {
        usuarioLogado,
        token,
        login,
        logout,
        isUsuarioLogado,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const isAuthenticaded = () => {
    
    let token = localStorage.getItem('token');
    if (token) {
        return true;
    } else {
        return false;
    }

};
