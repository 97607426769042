import React, {useState, useEffect, useRef} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/login.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask from 'react-input-mask';

import EmailIcon from '@mui/icons-material/Email';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import { api } from '../apis/connect';
import { useAuth } from '../contexts/AuthContext';

function Home() {

    const { login, token } = useAuth();
    const inputname = useRef(null);

    const [loading, setLoading] = useState(false);

    let obrigatorio = {
        "nome": true,
        "telefone": true,
        "email": false,
        "cpf": false,
        "quantidade": true,
        "rifa": true
    }

    useEffect(() => {

    }, [])

    function handleSubmit(e) {
        e.preventDefault();

        setLoading(true);

        api.cliente.pagamento.create(
            e.target.rifa.value,
            e.target.quantidade.value,
            e.target.nome.value,
            e.target.email.value,
            e.target.telefone.value,
            e.target.cpf.value,
            token
        ).then((response) => {

            setLoading(false);

            if (response.error) {
                toast.error(response.error);
                return;
            }

            toast.success('Números adicionados com sucesso!');
            
            setTimeout(() => {
                window.location.reload();
            }, 3000);

        }).catch(error => {
            setLoading(false);
            toast.error('Erro ao adicionar números');
        });

    }

    let handlerChange = (e) => {

        api.cliente.exists(e.target.value, process.env.REACT_APP_EMPRESA).then((response) => {
    
                if (response?.id) {
                    inputname.current.value = response.nome;
                }

        });

    }

    return(
        <div>

            <section class="tela-1 d-flex justify-content-center text-center align-items-center flex-column">

            <div>
                    <h3>Cadastro de números</h3>
                    <div class="lead mb-3">Preencha os campos abaixo para adicionar números a clientes</div>

                    <Form name='login' onSubmit={e => handleSubmit(e)}>

                        <InputGroup className="mb-3 mt-4">
                            <InputGroup.Text id="basic-addon1"><DriveFileRenameOutlineIcon/></InputGroup.Text>
                            <Form.Control
                            name='nome'
                            placeholder={'Nome completo' + (obrigatorio.nome ? '' : ' (opcional)')}
                            aria-label="Nome completo"
                            aria-describedby="basic-addon1"
                            required={obrigatorio.nome ? 'Campo obrigatório' : true}
                            ref={inputname}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><LocalPhoneIcon/></InputGroup.Text>
                            <Form.Control
                            name='telefone'
                            placeholder={'Telefone' + (obrigatorio.telefone ? '' : ' (opcional)')}
                            aria-label="Telefone"
                            aria-describedby="basic-addon1"
                            onChange={(e) => handlerChange(e)}
                            required={obrigatorio.telefone ? 'Campo obrigatório' : false}
                            as={InputMask}
                            mask="(99) 99999-9999"
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><EmailIcon/></InputGroup.Text>
                            <Form.Control
                            name='email'
                            placeholder={'Email' + (obrigatorio.email ? '' : ' (opcional)')}
                            aria-label="Email"
                            aria-describedby="basic-addon1"
                            required={obrigatorio.email ? 'Campo obrigatório' : false}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><FingerprintIcon/></InputGroup.Text>
                            <Form.Control
                            name='cpf'
                            placeholder={'CPF' + (obrigatorio.cpf ? '' : ' (opcional)')}
                            aria-label="CPF"
                            aria-describedby="basic-addon1"
                            required={obrigatorio.cpf ? 'Campo obrigatório' : false}
                            as={InputMask}
                            mask="999.999.999-99"
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <Form.Control
                                name='quantidade'
                                placeholder={'Quantidade' + (obrigatorio.quantidade ? '' : ' (opcional)')}
                                aria-label="Quantidade"
                                aria-describedby="basic-addon1"
                                required={obrigatorio.quantidade ? 'Campo obrigatório' : false}
                                type='number'
                                min={1}
                                max={1000}
                                className='text-center'
                            />
                        </InputGroup>

                        <InputGroup className="">
                            <Form.Control
                                name='rifa'
                                placeholder={'Rifa' + (obrigatorio.rifa ? '' : ' (opcional)')}
                                aria-label="Rifa"
                                aria-describedby="basic-addon1"
                                required={obrigatorio.rifa ? 'Campo obrigatório' : false}
                                type='hidden'
                                readOnly
                                className='text-center'
                                value={6}
                            />
                        </InputGroup>

                        <Button className='py-2 px-5' type='submit' variant="success">
                            Adicionar
                        </Button>

                    </Form>
                    
                </div>

            </section>

            <LoadingSplash active={loading}/>
            <ToastContainer />

        </div>
    )
}

export default Home